export default [
  {
    title: 'Tableau de bord',
    route: 'espace-structure.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Mon abonnement',
    route: 'espace-structure.abonnement',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Historique des appels',
    route: 'espace-structure.statistiques',
    icon: 'CheckSquareIcon',

  },
]
